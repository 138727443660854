<template>
  <div v-if="show" class="modal-wrapper" @click="show = false">
    <div class="modal-body mx-auto rounded-2xl" @click.stop>
      <div class="modal-title mb-4">{{ title }}</div>
      <hr />
      <img src="/notable_logo_512x512.png" class="mx-auto my-6 w-40" />
      <div class="modal-description my-4 text-lg">{{ description }}</div>
      <slot />
      <div align="center">
        <button
          v-if="!cancel"
          class="mx-auto mt-4 rounded px-8 py-2 text-ivory"
          style="background: #160448"
          @click="show = false"
        >
          OK
        </button>
        <template v-else>
          <div class="flex justify-center gap-8 py-4">
            <nota-button
              class="w-32 text-center"
              bg="#CAEA02"
              @click="
                cancel();
                show = false;
              "
              >Cancel</nota-button
            >
            <nota-button
              class="w-32 text-center"
              bg="#00D568"
              @click="proceed"
              :disable="btnDisabled"
              >Proceed</nota-button
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    modelValue: Boolean,
    cancel: Boolean,
    title: String,
    description: String,
    btnDisabled: Boolean,
  });
  const emit = defineEmits(["update:modelValue", "proceed", "cancel"]);

  const show = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
  });

  const proceed = () => {
    emit("update:modelValue", false);
    emit("proceed");
  };

  const cancel = () => {
    emit("cancel");
  };
</script>
<style scoped lang="postcss">
  .modal-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
  }
  .modal-body {
    background: rgb(18, 17, 33);
    position: fixed;
    left: calc(50% - 12rem);
    top: 20%;
  }
  .modal-body {
    @apply w-96 rounded-2xl p-4;
  }
  .modal-title {
    @apply text-xl text-white;
  }
  .modal-description {
    @apply text-gray-200;
  }
</style>
